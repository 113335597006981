import BaseModel from '../core/BaseModel'
import Constants from '@/core/Constants'
//import gql from 'graphql-tag'
export default class ServiceOrderModel extends BaseModel {

    constructor() {
        super({ 
                name: "visit_order_item",
                tableName: 'visit_order_item',
                label: "Service order", 
                default_order: { key: 'updated_at', direction: 'desc' } }, 
            {
                check_box: {
                    name: "check_box",
                    label: " ",
                    type: "Computed",
                    custom: 'CheckBoxSelect',
                    computed: (data) => {
                        data.check_box = '';
                    },
                    filter: {}
                    // display: { list: true, add: false, edit: false }
                },
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            order: {
                refs: "order {id code status payment_method property {id code name} visit{id arrival}}",
                display: false
            },
            products: {
                refs: "products {id price qty}",
                display: false
            },
            type_of_price: {
                name: "type_of_price",
                display: false
            },
            service: {
                refs: "service {id lang_arr{id name id_lang} }",
                display: false
            },
            created: {
                refs: "created {id fullname}",
                display: false
            },
            update_user: {
                refs: "update_user {id fullname}",
                display: false
            },
            assign: {
                refs: "assign {id fullname}",
                display: false
            },
            order_code: {
                name: 'order_code',
                type: "String",
                label: 'Order ID',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.order_code = '';
                },
            },
            service_name: {
                name: 'service_name',
                type: "String",
                label: 'Service',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.service_name = '';
                },
            },
            booked: {
                name: 'booked',
                type: "String",
                label: 'Booked by',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.booked = '';
                },
            },
            property_code: {
                name: 'property_code',
                type: "CustomUrl",
                label: 'Property ID',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.property_code = '';
                },
            },
            property_name: {
                name: 'property_name',
                type: "CustomUrl",
                label: 'Property',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.property_name = '';
                },
            },
            price: {
                name: 'price',
                type: "number",
                inputOptions: {
                    mode: 'currency'
                },
                label: 'Price',
                class: 'right'
            },
            qty: {
                name: 'qty',
                type: "number",
                label: 'Qty',
                class: 'right'
            },
            total: {
                name: 'total',
                type: "String",
                label: 'Total',
                class: 'right',
                computed: (data) => {
                    data.total = '';
                },
            },
            payment_method: {
                name: 'payment_method',
                type: "Selection",
                custom: "SelectionText",
                label: 'Payment method',
                options: [
                    {value: 1, label: 'Online'}, {value: 2, label: 'Cash'}
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            arrival: { 
                name: 'arrival', 
                type: "Datetime",
                label: 'Arrival Date',
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.arrival = '';
                },
            },
            time_to_finish: { 
                name: 'time_to_finish', 
                type: "Datetime",
                label: 'Time to be finish',
                filter: { type: 'DateRange', match: 'contains'}
            },
            assign_name : { 
                name: 'assign_name', 
                type: "String",
                label: 'Assign',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.assign_name = '';
                },
            },
            status: { 
                 class: 'center custom_status_order',
                name: 'status', 
                type: "Selection",
                custom: "SelectionDropdown",
                dropdown: {
                    options: Constants.getConstans().SERVICE_STATUS,
                    label: 'label',
                    value: 'value'
                },
                label: 'Service status',
                options: Constants.getConstans().SERVICE_STATUS,
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' }
            },
            created_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Created date',
                filter: { type: 'DateRange', match: 'contains'},
            },
            updated_name : { 
                name: 'updated_name', 
                type: "String",
                label: 'Updated by',
                computed: (data) => {
                    data.updated_name = '';
                },
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Updated date'
            }
        },)
    }

}