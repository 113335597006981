import BaseModel from '../core/BaseModel'
export default class UserModel extends BaseModel {

    constructor() {
        super({
                name: "user",
                tableName: 'users',
                label: "521 owner",
                display_key: 'fullname'
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },

                avatar: {
                    name: "avatar",
                    label: "Avatar",
                    custom: 'image',
                    type: "Computed",
                    computed: (data, index) => {
                        data.avatar = index + 1;
                    },
                    //display: false,
                },
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Fullname',
                    // width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                email: {
                    name: 'email',
                    type: "String",
                    label: 'Email',
                    // width: 15,
                    filter: {type: 'input', match: 'contains'},
                },
                full_phone: {
                    name: 'full_phone',
                    type: "String",
                    label: 'Phone number',
                    // width: 15,
                    filter: {type: 'input', match: 'contains'},
                    display: false
                },
                is_verify_otp: {
                    name: "is_verify_otp",
                    label: "Verify email",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: true, label: 'Verified', style: 'color:  #000000;'},
                        {value: false, label: 'Not verified',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },
                /*total_own_property: {
                    name: 'total_own_property',
                    type: "String",
                    label: 'Property',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },*/
                available_from_to: {
                    name: "available_from_to",
                    label: "Availalbe form - to",
                    type: "Computed",
                    filter: { type: 'DateRange', match: 'contains'},
                    computed: (data) => {
                        data.available_from_to = '';
                    },
                },
                detail_get: {
                    refs:  'text_group_users note bank_account bank address prefix_phone phone available_from available_to   ',//obj_updated_by { id fullname }
                    display: false
                },
                active: {
                    name: "active",
                    label: "Trạng thái",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 1, label: 'Active', style: 'color:  #000000;'},
                        {value: 2, label: 'Unactive',style: 'color:  #000000;'},
                        {value: 3, label: 'Delete',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center ",
                },
                updated_by_key: {
                    name: "updated_by_key",
                    label: "Updated by",
                    type: "Computed",
                    computed: (data) => {
                        data.updated_by_key ='' ;
                    },
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Updated date',
                    filter: { type: 'DateRange', match: 'contains'}
                },
                role: {
                    name: 'role',
                    type: "String",
                    label: 'Type',
                    display: false
                },
            })
    }

}