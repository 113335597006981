import BaseModel from '../core/BaseModel'
import Constants from '@/core/Constants'
//import gql from 'graphql-tag'
export default class VisitModel extends BaseModel {

    constructor() {
        super({ 
                name: "visit",
                tableName: 'visit',
                label: "Visit management", 
                default_order: { key: 'updated_at', direction: 'desc' } }, 
            {
            stt: {
                name: "stt",
                label: "No.",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            property: {
                refs: "property {id code name}",
                display: false
            },
            reports: {
                refs: "reports {id deleted}",
                display: false
            },
            orders: {
                refs: "orders {id items{id service{id}}}",
                display: false
            },
            requests: {
                refs: "requests {id items{id obj_service{id}}}",
                display: false
            },
            peoples: {
                refs: "peoples {id}",
                display: false
            },
            update_user: {
                refs: "update_user {id fullname}",
                display: false
            },
            owner: {
                refs: "owner {id fullname}",
                display: false
            },
            user: {
                refs: "user {id role fullname}",
                display: false
            },
            children: {
                name: "children",
                display: false
            },
            adults: {
                name: "adults",
                display: false
            },
            is_owner: {
                name: "is_owner",
                display: false
            },
            contact_name: {
                name: "contact_name",
                display: false
            },
            check_out_time: {
                name: "check_out_time",
                display: false
            },
            check_in_time: {
                name: "check_in_time",
                display: false
            },
            property_code: {
                name: 'property_code',
                type: "CustomUrl",
                label: 'Property ID',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.property_code = '';
                },
            },
            property_name: {
                name: 'property_name',
                type: "CustomUrl",
                label: 'Property',
                custom: 'SelectionText',
               // models: new PropertyModel(),
                filter: {type: 'input', match: 'contains'},
                class:"left",
                computed: (data) => {
                    data.property_name = '';
                },
            },
            visit_start: { 
                name: 'visit_start',
                display: false
            },
            visit_end: { 
                name: 'visit_end',
                display: false
            },
            week_number: { 
                name: 'week_number',
                display: false
            },
            visit: { 
                name: 'visit', 
                type: "String",
                label: 'Visit',
                sortable: false,
                // width: 8,
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.visit = '';
                },
            },
            owner_name: {
                name: 'owner_name',
                type: "CustomUrl",
                label: 'Owner',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.owner_name = '';
                },
            },
            who_confirmed: {
                name: 'who_confirmed',
                type: "String",
                label: 'Who confirmed',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.who_confirmed = '';
                },
            },
            who_stay: {
                name: 'who_stay',
                type: "String",
                label: 'Who stay',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.who_stay = '';
                },
            },
            status: {
                name: 'status',
                type: "Selection",
                label: 'Status',
                options: Constants.getConstans().VISIT_STATUS,
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            },
            arrival: { 
                name: 'arrival', 
                type: "Datetime",
                label: 'Arrival',
                filter: { type: 'DateRange', match: 'contains'}
            },
            departure: { 
                name: 'departure', 
                type: "Datetime",
                label: 'Departure',
                filter: { type: 'DateRange', match: 'contains'}
            },
            stay_days : { 
                name: 'stay_days', 
                type: "String",
                label: 'Days of stay in',
                computed: (data) => {
                    data.stay_days = '';
                },
            },
            people : { 
                name: 'people', 
                type: "String",
                label: 'People',
                computed: (data) => {
                    data.people = '';
                },
            },
            service : { 
                name: 'service', 
                type: "String",
                label: 'Service',
                computed: (data) => {
                    data.service = '';
                },
            },
            report : { 
                name: 'report', 
                type: "String",
                label: 'Report',
                computed: (data) => {
                    data.report = '';
                },
            },
            updated_name : { 
                name: 'updated_name', 
                type: "String",
                label: 'Updated by',
                computed: (data) => {
                    data.updated_name = '';
                },
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Updated date'
            },
            detail_get1: {
                refs:  'view_history_visit_confirm { created_by_obj { fullname } } uid_fullname  text_group_uid_fullname_contact_name',
                display: false
            },
        },)
    }

}